import {
    semanticBackgroundColors,
    semanticBodyColors,
    semanticBorderColors,
    semanticHeadingColors,
    semanticLinkColors,
    semanticTokens,
    semanticValidationColors,
} from './semanticTokens';

export const numericSpacing = {
    '0': 0,
    px: 1,
    '0.5': 2,
    '1': 4,
    '1.5': 6,
    '2': 8,
    '2.5': 10,
    '3': 12,
    '4': 16,
    '5': 20,
    '6': 24,
    '7': 28,
    '8': 32,
    '9': 36,
    '10': 40,
    '12': 48,
    '14': 56,
    '16': 64,
    '20': 80,
    '24': 96,
    '28': 112,
    '32': 128,
} as const;

/* eslint-disable max-len */
export const spacing = {
    '0': `${numericSpacing['0']}`,
    px: `${numericSpacing.px}px`,
    '0.5': `${numericSpacing['0.5']}px`,
    '1': `${numericSpacing['1']}px`,
    '1.5': `${numericSpacing['1.5']}px`,
    '2': `${numericSpacing['2']}px`,
    '2.5': `${numericSpacing['2.5']}px`,
    '3': `${numericSpacing['3']}px`,
    '4': `${numericSpacing['4']}px`,
    '5': `${numericSpacing['5']}px`,
    '6': `${numericSpacing['6']}px`,
    '7': `${numericSpacing['7']}px`,
    '8': `${numericSpacing['8']}px`,
    '9': `${numericSpacing['9']}px`,
    '10': `${numericSpacing['10']}px`,
    '12': `${numericSpacing['12']}px`,
    '14': `${numericSpacing['14']}px`,
    '16': `${numericSpacing['16']}px`,
    '20': `${numericSpacing['20']}px`,
    '24': `${numericSpacing['24']}px`,
    '28': `${numericSpacing['28']}px`,
    '32': `${numericSpacing['32']}px`,
} as const;

export const margin = {
    auto: 'auto',
    autoX: '0 auto',
    autoY: 'auto 0',
} as const;

export const blueColors = {
    blue_100: '#F0F4FF',
    blue_200: '#D4DEFB',
    blue_300: '#B9C9F7',
    blue_400: '#9DB4F3',
    blue_500: '#819EF0',
    blue_600: '#6689EC',
    blue_700: '#446EE7',
    blue_800: '#3F63C8',
    blue_900: '#3453A8',
    blue_1000: '#284389',
    blue_1100: '#1D3369',
    blue_1200: '#122349',
};

export const businessColors = {
    business_100: '#F2F5FF',
    business_200: '#E6EBFF',
    business_300: '#DCDFFF',
    business_400: '#D2D3FF',
    business_500: '#BBB9FF',
    business_600: '#A6A1FF',
    business_700: '#8E88FF',
    business_800: '#6460E6',
    business_900: '#504DBF',
    business_1000: '#3C3A99',
    business_1100: '#282773',
    business_1200: '#18184D',
};

export const greenColors = {
    green_100: '#E8FAF4',
    green_200: '#D0F7E6',
    green_300: '#A6EACA',
    green_400: '#84DFB5',
    green_500: '#62D49F',
    green_600: '#3FCA89',
    green_700: '#1DBF73',
    green_800: '#00AA5A',
    green_900: '#008A37',
    green_1000: '#00732E',
    green_1100: '#005C25',
    green_1200: '#003912',
};

export const greyColors = {
    grey_100: '#FAFAFA',
    grey_200: '#F5F5F5',
    grey_300: '#EFEFF0',
    grey_400: '#E4E5E7',
    grey_500: '#DADBDD',
    grey_600: '#C5C6C9',
    grey_700: '#B5B6BA',
    grey_800: '#95979D',
    grey_900: '#74767E',
    grey_1000: '#62646A',
    grey_1100: '#404145',
    grey_1200: '#222325',
};

export const lightOrangeColors = {
    lightOrange_100: '#F8F9E5',
    lightOrange_200: '#F1F4CB',
    lightOrange_300: '#EDF2B3',
    lightOrange_400: '#E8F094',
    lightOrange_500: '#E3EE74',
    lightOrange_600: '#DAEA3D',
    lightOrange_700: '#D0E500',
    lightOrange_800: '#BBCE00',
    lightOrange_900: '#7D8900',
    lightOrange_1000: '#687200',
    lightOrange_1100: '#465A00',
    lightOrange_1200: '#254200',
};

export const orangeColors = {
    orange_100: '#FFF6F2',
    orange_200: '#FFE0D4',
    orange_300: '#FFC9B4',
    orange_400: '#FFA987',
    orange_500: '#FF976E',
    orange_600: '#FF8758',
    orange_700: '#FF7640',
    orange_800: '#E0612E',
    orange_900: '#C65325',
    orange_1000: '#8F2900',
    orange_1100: '#6F2000',
    orange_1200: '#421300',
};

export const pinkColors = {
    pink_100: '#FFF5F8',
    pink_200: '#FFD3E2',
    pink_300: '#FFC3D8',
    pink_400: '#FFB1CD',
    pink_500: '#FFA1C3',
    pink_600: '#FF8EB7',
    pink_700: '#FF80AE',
    pink_800: '#EF6D94',
    pink_900: '#D95F83',
    pink_1000: '#BE5272',
    pink_1100: '#983E59',
    pink_1200: '#4D1727',
};

export const purpleColors = {
    purple_100: '#F8F0FF',
    purple_200: '#E8D4FB',
    purple_300: '#D8B9F7',
    purple_400: '#C89DF3',
    purple_500: '#B881F0',
    purple_600: '#A866EC',
    purple_700: '#984AE8',
    purple_800: '#8842D0',
    purple_900: '#7739B9',
    purple_1000: '#6731A1',
    purple_1100: '#56288A',
    purple_1200: '#462072',
};

export const redColors = {
    red_100: '#FFEDED',
    red_200: '#FED0D0',
    red_300: '#FCB3B3',
    red_400: '#FB9797',
    red_500: '#FA7A7A',
    red_600: '#F85D5D',
    red_700: '#F74040',
    red_800: '#D93C3C',
    red_900: '#C43333',
    red_1000: '#AB2D2D',
    red_1100: '#912626',
    red_1200: '#782020',
};

export const tealColors = {
    teal_100: '#ECFCFF',
    teal_200: '#CDF5FB',
    teal_300: '#AFEDF7',
    teal_400: '#92E6F3',
    teal_500: '#75DFEE',
    teal_600: '#57D7EA',
    teal_700: '#3AD0E6',
    teal_800: '#30B8CB',
    teal_900: '#25A1B0',
    teal_1000: '#1B8996',
    teal_1100: '#10727B',
    teal_1200: '#065A60',
};

export const yellowColors = {
    yellow_100: '#FFF7EE',
    yellow_200: '#FFECD1',
    yellow_300: '#FFE0B3',
    yellow_400: '#FFD596',
    yellow_500: '#FFCA79',
    yellow_600: '#FFBE5B',
    yellow_700: '#FFB33E',
    yellow_800: '#E6A139',
    yellow_900: '#CD8E33',
    yellow_1000: '#B47C2E',
    yellow_1100: '#9B6928',
    yellow_1200: '#825723',
};

export const whiteColors = {
    white: '#fff',
    white_10: 'rgba(255, 255, 255, 0.1)',
    white_20: 'rgba(255, 255, 255, 0.2)',
    white_50: 'rgba(255, 255, 255, 0.5)',
    white_80: 'rgba(255, 255, 255, 0.8)',
    white_90: 'rgba(255, 255, 255, 0.9)',
};

export const blackColors = {
    black: '#0C0C0D',
    black_10: 'rgba(98, 100, 106, 0.2)',
    black_20: 'rgba(98, 100, 106, 0.25)',
    black_50: 'rgba(98, 100, 106, 0.4)',
};

export const colors = {
    transparent: 'transparent',
    inherit: 'inherit',
    currentColor: 'currentColor',
    ...blackColors,
    ...whiteColors,
    ...blueColors,
    ...businessColors,
    ...greenColors,
    ...greyColors,
    ...lightOrangeColors,
    ...orangeColors,
    ...pinkColors,
    ...purpleColors,
    ...redColors,
    ...tealColors,
    ...yellowColors,
} as const;

export const backgroundColors = {
    ...semanticBackgroundColors,
    ...colors,
} as const;

export const validationColors = {
    validationError: semanticTokens.validationError,
    validationInformation: semanticTokens.validationInformation,
    validationSuccess: semanticTokens.validationSuccess,
    validationWarning: semanticTokens.validationWarning,
} as const;

export const bodyColors = {
    white: colors.white,
    inherit: colors.inherit,
    ...semanticBodyColors,
    ...validationColors,
};

export const headingColors = {
    white: colors.white,
    inherit: colors.inherit,
    ...semanticHeadingColors,
};

export const linkColors = {
    ...semanticLinkColors,
    ...colors,
} as const;

export const typographyColors = {
    ...linkColors,
    ...headingColors,
    ...bodyColors,
    ...colors,
} as const;

export const borderColors = {
    ...semanticBorderColors,
    ...validationColors,
    ...colors,
} as const;

export const borderStyle = {
    dashed: 'dashed',
    solid: 'solid',
} as const;

export const outlineStyle = {
    solid: 'solid',
} as const;

export const boxShadows = {
    aa: '0 0 .880517px rgba(0, 0, 0, .0288), 0 0 2.29197px rgba(0, 0, 0, .03), 0 0 5.52px rgba(0, 0, 0, .0543), 0 0 11px rgba(0, 0, 0, .09)',
    z1: '0 1.2px 1.92251px rgba(0, 0, 0, .02), 0 2px 3.68135px rgba(0, 0, 0, .04), 0 1px 5px rgba(0, 0, 0, .05)',
    z2: '0 .14px 2.29266px rgba(0, 0, 0, .0323114), 0 .37px 4.42626px rgba(0, 0, 0, .0476886), 0 3px 7px rgba(0, 0, 0, .09)',
    z3: '0 .08px 2.55728px rgba(0, 0, 0, .0525061), 0 1.28px 5.57858px rgba(0, 0, 0, .0774939), 0 3px 10px rgba(0, 0, 0, .13)',
    z4: '0 .266004px 1.13052px rgba(0, 0, 0, .0686618), 0 .893452px 3.79717px rgba(0, 0, 0, .101338), 0 5px 17px rgba(0, 0, 0, .17)',
    z5: '0 0 2.17382px rgba(0, 0, 0, .0487776), 0 1.75px 6.01034px rgba(0, 0, 0, .07), 0 3.63px 14.4706px rgba(0, 0, 0, .0912224), 0 22px 48px rgba(0, 0, 0, .14)',
} as const;

export const dropShadows = {
    aa: 'drop-shadow(0 0 .880517px rgba(0, 0, 0, .0288)) drop-shadow(0 0 2.29197px rgba(0, 0, 0, .03)) drop-shadow(0 0 5.52px rgba(0, 0, 0, .0543)) drop-shadow(0 0 11px rgba(0, 0, 0, .09))',
    z1: 'drop-shadow(0 1.2px 1.92251px rgba(0, 0, 0, .02)) drop-shadow(0 2px 3.68135px rgba(0, 0, 0, .04)) drop-shadow(0 1px 5px rgba(0, 0, 0, .05))',
    z2: 'drop-shadow(0 .14px 2.29266px rgba(0, 0, 0, .0323114)) drop-shadow(0 .37px 4.42626px rgba(0, 0, 0, .0476886)) drop-shadow(0 3px 7px rgba(0, 0, 0, .09))',
    z3: 'drop-shadow(0 .08px 2.55728px rgba(0, 0, 0, .0525061)) drop-shadow(0 1.28px 5.57858px rgba(0, 0, 0, .0774939)) drop-shadow(0 3px 10px rgba(0, 0, 0, .13))',
    z4: 'drop-shadow(0 .266004px 1.13052px rgba(0, 0, 0, .0686618)) drop-shadow(0 .893452px 3.79717px rgba(0, 0, 0, .101338)) drop-shadow(0 5px 17px rgba(0, 0, 0, .17))',
    z5: 'drop-shadow(0 0 2.17382px rgba(0, 0, 0, .0487776)) drop-shadow(0 1.75px 6.01034px rgba(0, 0, 0, .07)) drop-shadow(0 3.63px 14.4706px rgba(0, 0, 0, .0912224)) drop-shadow(0 22px 48px rgba(0, 0, 0, .14))',
} as const;

export const borderWidth = {
    sm: '1px',
    md: '2px',
    lg: '3px',
    xl: '4px',
    0: '0',
} as const;

export const headingFontSizes = {
    h_xxs: '16px',
    h_xs: '18px',
    h_sm: '20px',
    h_md: '24px',
    h_lg: '28px',
    h_xl: '32px',
    h_xxl: '40px',
} as const;

export const bodyFontSizes = {
    b_xs: '12px',
    b_sm: '14px',
    b_md: '16px',
    b_lg: '18px',
    b_xl: '20px',
    b_xxl: '24px',
} as const;

export const typographyFontSizes = {
    b_xxs: '10px',
    ...bodyFontSizes,
    ...headingFontSizes,
    h_3xl: '48px',
    h_4xl: '56px',
    h_5xl: '64px',
    h_6xl: '80px',
} as const;

export const headingLineHeights = {
    h_xxs: '22px',
    h_xs: '25px',
    h_sm: '28px',
    h_md: '32px',
    h_lg: '36px',
    h_xl: '38px',
    h_xxl: '48px',
} as const;

export const bodyLineHeights = {
    b_xs: '18px',
    b_sm: '22px',
    b_md: '24px',
    b_lg: '26px',
    b_xl: '28px',
    b_xxl: '32px',
} as const;

export const typographyLineHeights = {
    b_xxs: '14px',
    ...bodyLineHeights,
    ...headingLineHeights,
    h_3xl: '56px',
    h_4xl: '66px',
    h_5xl: '74px',
    h_6xl: '92px',
} as const;

export const headingSizes = {
    h_xxs: 'h_xxs',
    h_xs: 'h_xs',
    h_sm: 'h_sm',
    h_md: 'h_md',
    h_lg: 'h_lg',
    h_xl: 'h_xl',
    h_xxl: 'h_xxl',
} as const;

export const bodySizes = {
    b_xs: 'b_xs',
    b_sm: 'b_sm',
    b_md: 'b_md',
    b_lg: 'b_lg',
    b_xl: 'b_xl',
    b_xxl: 'b_xxl',
} as const;

export const typographySizes = {
    b_xxs: 'b_xxs',
    ...bodySizes,
    ...headingSizes,
    h_3xl: 'h_3xl',
    h_4xl: 'h_4xl',
    h_5xl: 'h_5xl',
    h_6xl: 'h_6xl',
} as const;

export const borderRadiuses = {
    none: '0',
    sm: '4px',
    md: '6px',
    lg: '8px',
    xl: '12px',
    '2xl': '16px',
    '3xl': '20px',
    circle: '9999px',
} as const;

export const fontStyle = {
    normal: 'normal',
    italic: 'italic',
    oblique: 'oblique',
} as const;

const primaryFont = '"Macan", "Helvetica Neue", Helvetica, Arial, sans-serif';

export const fontFamily = {
    primary: primaryFont,
    domaine: '"DomaineDisplay", "Helvetica Neue", Helvetica, Arial, sans-serif',
    accent: primaryFont,
    serif: 'Georgia, "Times New Roman", serif',
} as const;

export const fontWeight = {
    normal: '400',
    semibold: '600',
    bold: '700',
    inherit: 'inherit',
} as const;

export const textDecoration = {
    underline: 'underline',
    overline: 'overline',
    lineThrough: 'line-through',
    none: 'none',
    inherit: 'inherit',
} as const;

export const textOverflow = {
    ellipsis: 'ellipsis',
    unset: 'unset',
} as const;

export const pointerEvents = {
    none: 'none',
} as const;

export const overflow = {
    visible: 'visible',
    hidden: 'hidden',
    scroll: 'scroll',
    auto: 'auto',
    clip: 'clip',
} as const;

export const whiteSpace = {
    nowrap: 'nowrap',
    unset: 'unset',
    preWrap: 'pre-wrap',
} as const;

export const flexDirection = {
    column: 'column',
    row: 'row',
    rowReverse: 'row-reverse',
    columnReverse: 'column-reverse',
} as const;

export const aspectRatio = {
    '16/9': '16/9',
    '1': '1',
    '1.66/1': '1.66/1',
    '1.33/1': '1.33/1',
} as const;

export const textAlign = {
    start: 'start',
    center: 'center',
    end: 'end',
} as const;

export const display = {
    block: 'block',
    inline: 'inline',
    inlineBlock: 'inline-block',
    flex: 'flex',
    inlineFlex: 'inline-flex',
    grid: 'grid',
    inlineGrid: 'inline-grid',
    contents: 'contents',
    none: 'none',
    initial: 'initial',
    webkitBox: '-webkit-box',
} as const;

const BaseFlexAlignment = {
    flexStart: 'flex-start',
    center: 'center',
    flexEnd: 'flex-end',
    stretch: 'stretch',
    baseline: 'baseline',
} as const;

export const justifyContent = {
    ...BaseFlexAlignment,
    spaceAround: 'space-around',
    spaceEvenly: 'space-evenly',
    spaceBetween: 'space-between',
} as const;

export const flexAlignment = {
    ...BaseFlexAlignment,
    baseline: 'baseline',
};

export const flexWrap = {
    nowrap: 'nowrap',
    wrap: 'wrap',
    wrapReverse: 'wrap-reverse',
} as const;

const numberValues = {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9',
    '10': '10',
    '11': '11',
    '12': '12',
    '13': '13',
    '14': '14',
    '15': '15',
    '16': '16',
} as const;

export const gridLine = {
    ...numberValues,
    '-1': '-1',
} as const;

export const gridTemplates = {
    '7_cols': 'repeat(7, 1fr)',
    '12_cols': 'repeat(12, 1fr)',
    '15_cols': 'repeat(15, 1fr)',
    '100%': 'minmax(0, 1fr)',
    '25%_75%': 'minmax(0, 1fr) minmax(0, 3fr)',
    '75%_25%': 'minmax(0, 3fr) minmax(0, 1fr)',
    '70%_30%': 'minmax(0, 7fr) minmax(0, 3fr)',
    '30%_70%': 'minmax(0, 3fr) minmax(0, 7fr)',
    '33%_33%_33%': 'minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) ',
    '50%_50%': 'minmax(0, 1fr) minmax(0, 1fr)',
    '25%_25%_25%_25%': 'minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)',
    '25%_25%_50%': 'minmax(0, 1fr) minmax(0, 1fr) minmax(0, 2fr)',
    '25%_50%_25%': 'minmax(0, 1fr) minmax(0, 2fr) minmax(0, 1fr) ',
    '50%_25%_25%': 'minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr) ',
    '20%_20%_20%_20%_20%': 'minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)',
} as const;

export const gridAuto = {
    auto: 'auto',
    minContent: 'min-content',
    maxContent: 'max-content',
} as const;

export const boxSizing = {
    borderBox: 'border-box',
    unset: 'unset',
} as const;

export const position = {
    static: 'static',
    relative: 'relative',
    absolute: 'absolute',
    fixed: 'fixed',
    sticky: 'sticky',
} as const;

export const cursor = {
    pointer: 'pointer',
    copy: 'copy',
    auto: 'auto',
    wait: 'wait',
    zoomIn: 'zoom-in',
    zoomOut: 'zoom-out',
    progress: 'progress',
    move: 'move',
    grab: 'grab',
    grabbing: 'grabbing',
    notAllowed: 'not-allowed',
    default: 'default',
} as const;

export const userSelect = {
    none: 'none',
    auto: 'auto',
    text: 'text',
    all: 'all',
} as const;

export const listStyleType = {
    none: 'none',
    decimal: 'decimal',
    initial: 'initial',
} as const;

export const elements = {
    typography: {
        heading: {
            h1: 'h1',
            h2: 'h2',
            h3: 'h3',
            h4: 'h4',
            h5: 'h5',
            h6: 'h6',
        },
        body: {
            small: 'small',
            blockquote: 'blockquote',
            span: 'span',
            p: 'p',
            pre: 'pre',
            b: 'strong',
            strong: 'strong',
            abbr: 'abbr',
            bdi: 'bdi',
            cite: 'cite',
            mark: 'mark',
            time: 'time',
            a: 'a',
            u: 'u',
        },
    },
    box: {
        div: 'div',
        span: 'span',
        article: 'article',
        aside: 'aside',
        footer: 'footer',
        main: 'main',
        nav: 'nav',
        section: 'section',
        address: 'address',
        figcaption: 'figcaption',
        details: 'details',
        figure: 'figure',
        summary: 'summary',
        ul: 'ul',
        li: 'li',
        a: 'a',
        label: 'label',
        header: 'header',
    },
} as const;

export const visibility = {
    visible: 'visible',
    hidden: 'hidden',
    collapse: 'collapse',
} as const;

export const numericBreakpoints = {
    sm: 600,
    md: 900,
    lg: 1160,
    xl: 1400,
    xxl: 1760,
};

export const numericBreakpointsReverse = {
    sm: numericBreakpoints.sm - 1,
    md: numericBreakpoints.md - 1,
    lg: numericBreakpoints.lg - 1,
    xl: numericBreakpoints.xl - 1,
    xxl: numericBreakpoints.xxl - 1,
};

export const breakpoints = {
    sm: `screen and (min-width: ${numericBreakpoints.sm}px)`,
    md: `screen and (min-width: ${numericBreakpoints.md}px)`,
    lg: `screen and (min-width: ${numericBreakpoints.lg}px)`,
    xl: `screen and (min-width: ${numericBreakpoints.xl}px)`,
    xxl: `screen and (min-width: ${numericBreakpoints.xxl}px)`,
} as const;

export const breakpointsReverse = {
    sm: `screen and (max-width: ${numericBreakpoints.sm - 1}px)`,
    md: `screen and (max-width: ${numericBreakpoints.md - 1}px)`,
    lg: `screen and (max-width: ${numericBreakpoints.lg - 1}px)`,
    xl: `screen and (max-width: ${numericBreakpoints.xl - 1}px)`,
    xxl: `screen and (max-width: ${numericBreakpoints.xxl - 1}px)`,
} as const;

export const numericAnimationDurations = {
    fast1: 0.07 * 1000,
    fast2: 0.1 * 1000,
    fast3: 0.12 * 1000,
    moderate1: 0.2 * 1000,
    moderate2: 0.25 * 1000,
    moderate3: 0.3 * 1000,
    slow1: 0.37 * 1000,
    slow2: 0.4 * 1000,
    slow3: 0.5 * 1000,
} as const;

export const animationDuration = {
    fast1: `${numericAnimationDurations.fast1}ms`,
    fast2: `${numericAnimationDurations.fast2}ms`,
    fast3: `${numericAnimationDurations.fast3}ms`,
    moderate1: `${numericAnimationDurations.moderate1}ms`,
    moderate2: `${numericAnimationDurations.moderate2}ms`,
    moderate3: `${numericAnimationDurations.moderate3}ms`,
    slow1: `${numericAnimationDurations.slow1}ms`,
    slow2: `${numericAnimationDurations.slow2}ms`,
    slow3: `${numericAnimationDurations.slow3}ms`,
} as const;

export const animationTiming = {
    easeIn: 'cubic-bezier(0, 0.7, 0, 1)',
    easeOut: 'cubic-bezier(0, 0, 1, 0.3)',
    easeInOut: 'cubic-bezier(0.75, 0, 0.25, 1)',
    linear: 'cubic-bezier(0, 0, 1, 1)',
} as const;

export const outlineOffset = {
    '0': '0',
    sm: '1px',
    lg: '4px',
} as const;

export const outlineWidth = {
    sm: '1px',
    md: '2px',
} as const;

export const overflowWrap = {
    anywhere: 'anywhere',
    breakWord: 'break-word',
} as const;

export const wordBreak = {
    breakAll: 'break-all',
    keepAll: 'keep-all',
} as const;

const BASE_Z_INDEX = 10000;

export const zIndex = {
    auto: 'auto',
    '-1': '-1',
    '0': '0',
    '1': '1',
    '2': '2',
    overlayBackground: `${BASE_Z_INDEX + 1300}`,
    overlay: `${BASE_Z_INDEX + 1400}`,
    popover: `${BASE_Z_INDEX + 1500}`,
    toast: `${BASE_Z_INDEX + 1600}`,
    tooltip: `${BASE_Z_INDEX + 1700}`,
    educationTooltip: `${BASE_Z_INDEX + 1900}`,
} as const;

export const opacity = {
    '0': '0',
    '0.1': '0.1',
    '0.2': '0.2',
    '0.5': '0.5',
    '0.6': '0.6',
    '0.8': '0.8',
    '0.9': '0.9',
    '1': '1',
} as const;

export const appearance = {
    none: 'none',
    textfield: 'textfield',
} as const;

export const objectFit = {
    none: 'none',
    unset: 'unset',
    fill: 'fill',
    contain: 'contain',
    cover: 'cover',
} as const;

export const webkitBoxOrient = {
    vertical: 'vertical',
} as const;

export {
    semanticBackgroundColors,
    semanticBodyColors,
    semanticBorderColors,
    semanticHeadingColors,
    semanticLinkColors,
    semanticTokens,
    semanticValidationColors,
};
