export const semanticValidationColors = {
    validationError: 'red_1000',
    validationInformation: 'blue_1000',
    validationSuccess: 'green_1000',
    validationWarning: 'orange_1000',
} as const;

export const semanticBackgroundColors = {
    background: 'white',
} as const;

export const semanticBodyColors = {
    bodyPrimary: 'grey_1200',
    bodySecondary: 'grey_900',
    ...semanticValidationColors,
} as const;

export const semanticHeadingColors = {
    heading: 'grey_1200',
} as const;

export const semanticLinkColors = {
    linkPrimary: 'grey_1200',
    linkSecondary: 'green_900',
} as const;

export const semanticBorderColors = {
    border: 'grey_500',
    ...semanticValidationColors,
} as const;

export const semanticTokens = {
    ...semanticBackgroundColors,
    ...semanticBodyColors,
    ...semanticLinkColors,
    ...semanticBorderColors,
    ...semanticHeadingColors,
    ...semanticValidationColors,
} as const;
